import Ajv from "ajv";
const ajv = new Ajv();

export default class BugReportHandler {

    constructor() {
    }

    static bugReportDataSchema = {
        type: "object",
        properties: {
            name: { type: "string", minLength: 2 },
            email: { type: "string", format: "email" },
            message: { type: "string", minLength: 30 }
        },
        required: ["name", "email", "message"]
    };

    static validate = ajv.compile(this.bugReportDataSchema);

    static getFormData() {
        this.form = document.getElementById("bugReportForm");
        return {
            name: this.form.querySelector("[name='name']").value.trim(),
            email: this.form.querySelector("[name='email']").value.trim(),
            message: this.form.querySelector("[name='message']").value.trim(),
            attachment: this.form.querySelector("[name='attachment']").files[0],
        };
    }

    // Validate and toggle error messages
    static validateField(input, index) {
        let formData = this.getFormData();
        let valid = BugReportHandler.validate(formData);

        // Check if this specific field has an error
        let hasError = BugReportHandler.validate.errors?.some(e => e.dataPath.includes(input.name));

        const errorMessages = document.getElementById('bugReportForm').querySelectorAll(".error-message");

        if (hasError) {
            errorMessages[index].style.display = "block"; // Show error
            input.classList.add("invalid");
            return false;
        } else {
            errorMessages[index].style.display = "none"; // Hide error if fixed
            input.classList.remove("invalid");
            return true;
        }
    }

    // Helper: Convert file to int8Array
    static fileToUint8Array = async (file) => {
        const buffer = await file.arrayBuffer();
        return new Uint8Array(buffer);
    }

    static validateFormData = async (data) => {

        const res = BugReportHandler.validate(data);
        let params;
        let userUploadedFile;

        if (res) {

            let attachmentBase64 = null;
            if (data.attachment) {
                attachmentBase64 = await this.fileToUint8Array(data.attachment);
                userUploadedFile = {
                        filename: data.attachment.name,
                        data: attachmentBase64,
                        contentType: "image/png"
                };
            }

            params = {name: data.name, email: data.email, message: data.message};
        } else {
            console.warn(BugReportHandler.validate.errors);
        }

        let errors = BugReportHandler.validate.errors;
        return {params, userUploadedFile, errors};
    }

    static sendBugReport = async (event) => {

        event.preventDefault();

        if(!window.Sentry){
            console.warn("Sentry instance not set. Cannot send feedback.");
            return;
        }

        // Get form data
        const {name, email, message, attachment} = this.getFormData(event);
        let {params, userUploadedFile, errors} = await BugReportHandler.validateFormData({name, email, message, attachment});

        const hint = { attachments: [] };

        // Attaching the current state of the project, might not be saved yet
        if(window.s.projectObj) {
            const projectName = window.s.projectObj.meta.projectName || 'undefined';
            hint.attachments.push({
                filename: `${projectName}.spectitular`,
                data: JSON.stringify(window.s.projectObj),
            });
        }

        // attach user uploaded file
        if(userUploadedFile) {
            hint.attachments.push( userUploadedFile );
        }

        if(params) {
            if(!hint){
                window.Sentry.captureFeedback(params);
            }else{
                window.Sentry.captureFeedback(params, hint);
            }
            $.growl.notice({title: 'Feedback sent successfully', message: 'Thank you!'});
        }else{
            for(let error of errors) {
                console.error(error);
                $.growl.error({title: 'Feedback not sent', message: error.message});
            }
        }
    }

    static getProjectObject() {
        return JSON.stringify(window.s.projectObj);
    }
}
