function getSemVerComponents(version) {
    const match =
        /^v?(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/.exec(
            version,
        );

    if (!match) {
        throw new Error('Invalid version');
    }

    return {
        major: parseInt(match[1], 10),
        minor: parseInt(match[2], 10),
        patch: parseInt(match[3], 10),
        preRelease: match[4],
        build: match[5],
    };
}

function getEnvironmentFromVersion(version) {
    const environmentMap = {
        dev: 'development',
        alpha: 'development',
        beta: 'integration',
        '': 'production',
    };

    try {
        const semVerComponents = getSemVerComponents(version);
        if (!semVerComponents.preRelease) {
            return 'production';
        } else {
            return (
                environmentMap[semVerComponents.preRelease.split('.')[0]] ?? 'unknown'
            );
        }
    } catch (error) {
        console.warn("Couldn't determine environment based on version", error);
        return 'unknown';
    }
}

// CommonJS module.exports
module.exports = { getSemVerComponents, getEnvironmentFromVersion };