import * as Sentry from "@sentry/browser";
import {getEnvironmentFromVersion} from "./helpers/semver.util";
import BugReportHandler from "./sentry-common.js"

export const initSentryWebFrontend = async (windowName='n.a.') => {

    let RELEASE_VERSION = __APP_VERSION__;
    const SENTRY_PROJECT_NAME = 'spectitular';
    const ENVIRONMENT = getEnvironmentFromVersion(RELEASE_VERSION);

    if (['staging', 'production', 'integration'].includes(ENVIRONMENT)) {

        Sentry.init({
            dsn: "https://82a6c6f86b5491e23d065a92efce3bd1@o4508208337059840.ingest.de.sentry.io/4508833896595536",

            // Alternatively, use `process.env.npm_package_version` for a dynamic release version
            // if your build tool supports it.
            release: SENTRY_PROJECT_NAME + '@' + RELEASE_VERSION,
            environment: ENVIRONMENT,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.feedbackIntegration({
                    autoInject: false
                }),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            // Learn more at
            // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
            tracesSampleRate: 0.1,

            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            // Learn more at
            // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            debug: true

        });

        Sentry.setTag('web.process', 'frontend');
        Sentry.setTag('window.name', windowName);


        window.Sentry = Sentry;
    }
}
